<template>
  <div>
    <el-card class="box-card">
      <div>
        <div class="explain">
          <div class="mode">扣费方式:</div>
          <div>
            1.开通直播服务后，若未选择套餐类型，按照￥5.80元/每人/小时计算，费用将在直播结束统一进行扣除；
          </div>
          <div>
            2.若开通一或套餐二、套餐三、四、五，示例：观看人数101人，100人按照套餐收费，剩余1人，按照￥5.80元/每人/小时进行扣费；
          </div>
          <div>3.套餐一，二、三、四均需要选择使用时长，最短开通时长为一个月；</div>
        </div>
        <div class="meal">
          <div class="title">套餐</div>
          <div class="mealDetail">
            <div v-for="(item, index) in liveMealList" :key="item.livePackageId" class="charge">
              <div
                class="chargeInfo"
                :class="{ active: currentIndex == index }"
                @click="changeMeal(item, index)"
              >
                <div class="time">{{ item.description }}</div>
                <div class="money">
                  <span class="price">￥{{ item.chargePrice }}</span>
                  <span v-if="item.chargePrice == 5.8">/人/小时</span>
                  <span v-else>/月</span>
                </div>
                <div class="mealNum">{{ item.livePackageName }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="useTime">
          <div class="title">使用时长</div>
          <el-input-number
            v-model="month"
            :min="1"
            :max="10"
            @change="handleChange"
          ></el-input-number
          ><span class="month">月</span>
        </div>

        <div class="btnPay"><el-button type="primary" @click="submitBuy">确定购买</el-button></div>
      </div>
    </el-card>
  </div>
</template>

<script>
import to from 'await-to'
import { liveMeal } from '@/api/live'
export default {
  data() {
    return {
      liveMealList: [],
      currentIndex: -1,
      month: 1,
    }
  },
  created() {
    this.liveMealData()
  },
  methods: {
    async liveMealData() {
      const [res, err] = await to(liveMeal({}))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.liveMealList = res.data
    },
    changeMeal(item, i) {
      this.currentIndex = i
      this.itemMeal = item
    },
    handleChange(value) {
      this.month = value
    },
    async submitBuy() {
      if (this.currentIndex == -1) return this.$message({ type: 'warning', message: '请选择套餐' })
      this.$router.push({
        path: '/finance/detail',
        query: { liveDetail: JSON.stringify(this.itemMeal), month: this.month },
      })
    },
  },
}
</script>

<style scoped lang="scss">
.explain {
  padding: 14px 35px 24px 18px;
  background: #fff5f0;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  font-size: 12px;
  color: #606266;
  .mode {
    margin-bottom: 30px;
  }
  div {
    line-height: 20px;
  }
}
.meal {
  margin-top: 24px;
  .title {
    font-size: 16px;
    color: #333333;
  }
  .mealDetail {
    display: flex;
    flex-wrap: wrap;
    .charge {
      width: 20%;
      padding: 0 20px;
      box-sizing: border-box;
      margin: 10px 0;
      &:first-child {
        padding-left: 0;
      }
      .chargeInfo {
        border: 1px solid #e2e2e2;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        .time {
          font-size: 18px;
          color: #333;
          margin: 18px 0;
        }
        .money {
          font-size: 14px;
          color: #333;
          .price {
            font-size: 22px;
            color: #ff7b33;
            margin-bottom: 18px;
          }
        }
        .mealNum {
          background: #f3f3f3;
          text-align: center;
          font-size: 14px;
          color: #333;
          border-radius: 0px 0px 8px 8px;
          margin-top: 18px;
          width: 100%;
          line-height: 36px;
        }
      }
    }
  }
}
.useTime {
  margin-top: 25px;
  .title {
    margin-bottom: 10px;
    font-size: 16px;
    color: #333;
  }
  .month {
    font-size: 16px;
    color: #333;
    margin-left: 10px;
  }
}
.btnPay {
  text-align: center;
  margin: 100px 0;
}
.active {
  background: #fff8ec;
  border: 1px solid #eabb6a !important;
  .mealNum {
    background: #ffe8c0 !important;
    color: #b57d1d;
  }
}
</style>
